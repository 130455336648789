import { type TEnvirontment } from "@/types"

export const CDN_URL = process.env.NEXT_PUBLIC_CDN_URL ?? ""

export const AUTH = {
  BASE_URL: process.env.NEXT_PUBLIC_AUTH_BASE_URL_INTERNAL ?? "",
}

export const ANALYTICS = {
  GTM_ID: process.env.NEXT_PUBLIC_GTM_ID ?? "",
}

export const DOWNLOAD_REQUEST_URL =
  process.env.NEXT_PUBLIC_DOWNLOAD_REQUEST_URL ?? ""

export const ZENDESK_URL = process.env.NEXT_PUBLIC_ZENDESK_URL ?? ""
export const BUYER_URL = process.env.NEXT_PUBLIC_BUYER_URL ?? ""

export const NEXT_PUBLIC_ENV =
  (process.env.NEXT_PUBLIC_ENV as TEnvirontment) ?? ""

export const FEEDBACK_URL =
  "https://docs.google.com/forms/d/e/1FAIpQLSdCEzEoPrZUvLr34D4t0vHaAomxdLXJog4jARVb4JEiuNypDw/viewform?entry.1163411221=#auditorName&entry.1694717971=#instantionName"
